import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../../components/Layout'
import { RingBaseSelect } from '../../components/RingBaseSelect'
import { SEO } from '../../components/SEO'
import { Txt } from '../../components/Txt'
import { fullorderFormUrl } from '../../utils'

export const Head = () => (
    <SEO
        title="おふたりがデザイナーのCAD-platinum　| フルオーダーメイド事例一覧"
        description="簡単なデザインを写真で送るだけで完成する、理想的なリングのデザイン事例をご紹介。お客様のイメージが具現化された様々なリングを参考にすれば、おふたりの理想がきっとみつかります。"
        page="fullorder/catalog"
    />
)

const CATALOGS = [
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-3.jpg"
                alt=""
            />
        ),
        text: '定番のダイヤではなく、誕生石でもあるウェディングパールをセット。金、白金、真珠が放つ色彩が見事に調和。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-4.jpg"
                alt=""
            />
        ),
        text: 'プラチナリングに好きな言葉を入れた表面の刻印が、囲う様に留めたダイヤモンドによって、より存在感のあるテキストに。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-5.jpg"
                alt=""
            />
        ),
        text: "プラチナ950の深い照りにデザインの重厚感が絶妙に合う、スタイリッシュな結婚指輪。ダイヤの輝きが高級感とLady'sらしさも強調。",
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-6.jpg"
                alt=""
            />
        ),
        text: '人気の7pcダイヤ、プラチナリングの内径にK18ピンクゴールドの平打ちをセットしたコンビリング。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-7.jpg"
                alt=""
            />
        ),
        text: '甲側を山型で1周したリングレールの重厚感を、ピンクゴールドとダイヤモンドの可愛らしさで高級感のあるデザインに。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-9.jpg"
                alt=""
            />
        ),
        text: "両端の段差にミルを打ったプラチナ950ペアリング。Lady'sにはダイヤを3pcセット。",
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-10.jpg"
                alt=""
            />
        ),
        text: "お互いのイニシャル、「Y」と「M」のラインにダイヤモンドをセット。Lady'sはセンターラインにもメレダイヤを追加。",
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-11.jpg"
                alt=""
            />
        ),
        text: 'ladysはダイヤ2ピースを縦に並べて3か所にセット。mensは同じ間隔でつや消しを入れてお揃い感がしっかりきまるペアリング。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-12.jpg"
                alt=""
            />
        ),
        text: '細めのリングを重ね着けできる、計4本のペアリング。プラチナ、ゴールド、ダイヤモンドと、シーンに合わせた使い分けが楽しい結婚指輪。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-13.jpg"
                alt=""
            />
        ),
        text: 'おふたりの結婚記念日6月1日をダイヤモンドで記したペアリング。2本重ねてはじめて見える6.1が単品でもバランス良く輝くシンプルなアクセントに。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-14.jpg"
                alt=""
            />
        ),
        text: '甲丸のプラチナリングにK18ゴールドのレールを等間隔にセット。華やかで可愛らしいアンティーク感が魅力のペアリングに仕上がりました。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-15.jpg"
                alt=""
            />
        ),
        text: '精巧なカットラインが放つ神秘的な美しさと、3ピースのダイヤモンドが調和を極めたペアリング。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-16.jpg"
                alt=""
            />
        ),
        text: '足あとマークは着色ではなくリングレールにK18ゴールドをロウ付けしたプラチナとのコンビリング。シンプルでもオリジナルのアレンジを加えたい方におすすめ。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-17.jpg"
                alt=""
            />
        ),
        text: '2㎜幅のプラチナ950エタニティリングは、ダイヤモンドと9月の誕生石ピンクサファイアを交互に石留め。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-18.jpg"
                alt=""
            />
        ),
        text: '人気デザインのダイヤを境にプラチナとイエローゴールドのコンビにしたペアリング。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-19.jpg"
                alt=""
            />
        ),
        text: 'シンプルで人気の甲丸リングに、結婚記念日であるメモリアルナンバー「2020.5.11」をプラチナで造型。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-20.jpg"
                alt=""
            />
        ),
        text: 'ハートの鍵と錠をお互いのリングに加えた、シンプルでもオリジナル感が充実のプラチナペアリング。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-21.jpg"
                alt=""
            />
        ),
        text: 'ミル打ちラインに囲われたセンターの平打ち部分に、桜のオブジェクトを全周で並べたこだわりリング。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-22.jpg"
                alt=""
            />
        ),
        text: "ミル打ちラインに囲われたセンターの平打ち部分に、ダイヤを等間隔で留めたLady'sリングと、艶消し加工で落ち着いた雰囲気のMen'sリング。",
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-23.jpg"
                alt=""
            />
        ),
        text: '人気のウェーブダイヤリングにメモリアルナンバー「1.11」を加えたプラチナリング。',
    },
    {
        image: (
            <StaticImage
                src="../../images/fullorder/catalog/design-24.jpg"
                alt=""
            />
        ),
        text: '好みのデザインリングはプラチナで製作。推しキャラはK18ピンクゴールドでさりげないアクセントが成功。',
    },
]

const CatalogPage: React.FC = () => {
    return (
        <Layout
            breadcrumbs={[
                { name: 'フルオーダー', path: 'fullorder' },
                { name: '事例' },
            ]}
        >
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <HeroImageWrapper>
                        <StaticImage
                            src="../../images/fullorder/catalog/design-1.jpg"
                            alt="フルオーダーの結婚指輪事例"
                        />
                    </HeroImageWrapper>
                    <DescriptionWrapper>
                        <DescriptionTxt size="l">きっとある</DescriptionTxt>
                        <DescriptionTxt size="l">デザインの理想</DescriptionTxt>
                        <Txt>
                            最初はイメージが湧かない方も、様々なデザインを見ることでどんどんご自身の好みがはっきりしていきます。
                        </Txt>
                        <Txt>
                            オーダーデザインの事例を参考に、デザインの理想に辿りつけば、世界に一つのオリジナルリングを3つの製作コースからご利用頂けます。
                        </Txt>
                    </DescriptionWrapper>
                    <TitleImageWrapper>
                        <StaticImage
                            src="../../images/fullorder/catalog/design-2.jpg"
                            alt=""
                        />
                    </TitleImageWrapper>
                    {CATALOGS.map((catalog, index) => (
                        <React.Fragment key={index}>
                            <CatalogWrapper>
                                {catalog.image}
                                <CatalogTxt size="s">{catalog.text}</CatalogTxt>
                            </CatalogWrapper>
                            <Divider />
                        </React.Fragment>
                    ))}
                    <DescriptionWrapper>
                        <DescriptionTxt size="l">
                            “おふたり”がデザイナー
                        </DescriptionTxt>
                        <DescriptionTxt size="l">
                            フルオーダーを始めよう！
                        </DescriptionTxt>
                    </DescriptionWrapper>
                    <StaticImage
                        src="../../images/fullorder/catalog/design-3.jpg"
                        alt=""
                    />
                    <TitleImageWrapper>
                        <a href={fullorderFormUrl}>
                            <StaticImage
                                src="../../images/fullorder/full-8.jpg"
                                alt=""
                            />
                        </a>
                    </TitleImageWrapper>
                    <Divider />
                    <DescriptionWrapper>
                        <DescriptionTxt size="l">
                            “CADデザイナー”に
                        </DescriptionTxt>
                        <DescriptionTxt size="l">
                            デザイン画像作成依頼
                        </DescriptionTxt>
                    </DescriptionWrapper>
                    <StaticImage
                        src="../../images/fullorder/designer/designer-3.jpg"
                        alt=""
                    />
                    <TitleImageWrapper>
                        <Link to="/designerorder/">
                            <StaticImage
                                src="../../images/fullorder/full-8.jpg"
                                alt=""
                            />
                        </Link>
                    </TitleImageWrapper>
                    <Divider />
                    <DescriptionWrapper>
                        <DescriptionTxt>
                            デザインのイメージが湧かない場合は
                        </DescriptionTxt>
                        <DescriptionTxt>
                            リングのカスタマイズでデザインを検索
                        </DescriptionTxt>
                    </DescriptionWrapper>
                    <RingBaseSelect hideCatchTxt />
                </Grid>
            </Grid>
        </Layout>
    )
}

const HeroImageWrapper = styled('h1')({
    margin: 0,
})
const DescriptionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '20px 8px',
})
const DescriptionTxt = styled(Txt)({
    marginBottom: '16px',
})
const TitleImageWrapper = styled('div')({
    margin: '10px 0',
})
const CatalogWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})
const CatalogTxt = styled(Txt)({
    display: 'block',
    padding: '8px',
})
const Divider = styled('hr')({
    margin: '28px auto',
    width: '80%',
    borderStyle: 'none',
    borderTop: '1px solid #ccc',
})

export default CatalogPage
